const openmarketbill = {
  state: {
    beginUpdate: new Date(),
    query: {}
  },
  mutations: {
    set_openmarketbill_beginUpdate (state, data) {
      state.beginUpdate = data
    },
    set_openmarketbill_query (state, data) {
      state.query = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default openmarketbill
